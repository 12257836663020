(function( $ ) {

    var solutionBgAnimate = function(){

        $('.solution-item').each(function(){
            var itemwidth = $(this).width();
            var itemHeight = $(this).height();
            var itemTopPostion = $(this).offset().top;
            var scollTop = $(window).scrollTop() + $(window).height();
            var currentPosition = -parseFloat($(this).width() * 80 / 100) + (scollTop - itemTopPostion);
            var maxMove = parseFloat($(this).width()) / 2;
            // if(currentPosition < -maxMove){
            //     currentPosition = currentPosition;
            // }else{
            //     if(itemTopPostion < scollTop){
            //        console.log(itemTopPostion);
            //        currentPosition = -maxMove;
            //     }else if(){
            //        console.log(itemTopPostion+ 'ddd');
            //        currentPosition = -maxMove;
            //     } else{
            //         currentPosition = -(scollTop - itemTopPostion);
            //     }
            // }

            if(currentPosition < -maxMove){
                currentPosition = currentPosition;
            } else{
                currentPosition = -maxMove;
            }

            if($(this).find('.solution-bg').hasClass('from-right')){
                $(this).find('.solution-bg').css({
                    right: currentPosition,
                });
            }else{
                $(this).find('.solution-bg').css({
                    left: currentPosition,
                });
            }
        });

    };


    solutionBgAnimate();
    $(window).on('resize scroll', function(){
        solutionBgAnimate();
    });

    $('.info-slider').each(function(){
        $(this).owlCarousel({
            items: 1,
            sideby: 1,
            loop: false,
            nav: false,
            dots: true,
            lazyLoad: true,
            smartSpeed: 1000,
            margin: 20,
            responsive:{
                641:{
                    items: 2,
                    margin: 30,
                },
                992:{
                    items:3,
                }
            }
        });
    });

}(jQuery));
