var responsiveAcrossH = function(){
    var listHeight = $('.responsive-across ul li.desktop-view').height();
    console.log(listHeight);
    $('.responsive-across ul').find('li').not('.desktop-view').find('figure').height(listHeight);
};

(function( $ ) {

    //$('.responsive-across ul li figure').matchHeight();
    $('.product-option ul li').matchHeight();
    $('.prducts-opstions-info ul li').matchHeight();

    var deviceInfoLiCount = $('.device-compare-info').find('ul li').length;
    for (var i = 0; i < deviceInfoLiCount; i++) {
        liHeight = $('.device-compare-info').find('ul li.list-el'+i).matchHeight();
    }

    $('.device-comparison-slider').owlCarousel({
        items:1,
        sideby:1,
        loop: false,
        nav: false,
        dots: false,
        lazyLoad: true,
        smartSpeed: 1000,
        margin:20,
        responsive:{
            641:{
                items:2,
                margin:30,
            },
            992:{
                items:3,
                margin:50,
            }
        }
    });

    responsiveAcrossH();
    $(window).on('load resize', function(){
        responsiveAcrossH();
    });

}(jQuery));
