
var pageBannerSettings = function(){
    var $bannerImgSrc = $('.page-banner .banner-img').attr('src');
    $('.page-banner .banner-img').closest('.page-banner').css({
        'background-image' : 'url(' + $bannerImgSrc + ')',
        'height' : $bannerImgSrc
    });
    $('.page-banner .banner-img').remove();
};


var menuDropdownLink = function(){
    var windowWidth = $(window).width();
    var dpToggleLink = $('#header .dropdown').find('.dropdown-toggle');

    dpToggleLink.parent().find('.open-dropdown').remove();

    if(windowWidth <= 768){
        dpToggleLink.after('<span class="open-dropdown"><i class="glyphicon glyphicon-triangle-bottom"></i></span>');
        $(document).on('click', '#header .open-dropdown', function(e){
            e.stopPropagation();
            $(this).parent('.dropdown').find('[data-toggle=dropdown]').dropdown('toggle');
        });
    } else{
        dpToggleLink.parent().find('.open-dropdown').remove();
    }

    dpToggleLink.click(function() {
        var location = $(this).attr('href');
        window.location.href = location; return false;
    });

    // dpToggleLink.hover(function(){
    //     $(this).parent('.dropdown').find('[data-toggle=dropdown]').dropdown('toggle');
    // });
};

var is_touch_device = function() {
    return (('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
};


$('.same-height').matchHeight();
$('.same-height-all').matchHeight({byRow: false,});
new WOW().init();

$(document).ready(function(){

    //$('.lazy').Lazy();

    if (!is_touch_device()) {

        $("html").niceScroll({
            cursorcolor: "#fdbd30",
            cursoropacitymin: 0,
            cursoropacitymax: 0.5,
            cursorborder: 0,
            cursorwidth: "10px",
            zindex: 99999,
            scrollspeed: 50
        });

    }

    menuDropdownLink();
    pageBannerSettings(); 

    $(window).on('resize', function(){
        pageBannerSettings();
        menuDropdownLink();
    });

});
