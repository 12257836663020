(function( $ ) {

    var $item = $('#top-news-slider.carousel .item');
    $item.eq(0).addClass('active');
    //var $carouselImgHeight = $item.eq(0).find('img').height();
    //var $carouselImgHeight = $item.eq(0).find('.main-banner').height();

    var slideSettings = function (){
        var windowWidth = $(window).width();
        var windowHeight = $(window).height();
        var headerHeight = $('#header .navbar').outerHeight();

        //$item.addClass('full-screen');

        //$item.height($carouselImgHeight);

        // if(windowWidth > 767){
        //     if(windowHeight < windowWidth){
        //         $item.height(windowHeight - headerHeight);
        //     } else{
        //         $item.height($carouselImgHeight);
        //     }
        // } else{
        //     if(windowWidth < 640){
        //         $item.height(windowHeight / 2);
        //     } else{
        //         $item.height($carouselImgHeight);
        //     }
        // }
    };

    $('#top-news-slider.carousel img').each(function() {
        var $carouselImgSrc = $(this).attr('src');
        $(this).parent().find('.main-banner').css({
            'background-image' : 'url(' + $carouselImgSrc + ')',
            //'height' : $carouselImgHeight
        });
        $(this).remove();
    });

    $('#top-news-slider.carousel .carousel-caption p').each(function() {
        var $color = $(this).attr('data-color');
        $(this).css({
            'color' : $color
        });
    });

    slideSettings();

    $('#top-news-slider.carousel').carousel({
        interval: 10000,
        pause: "false"
    });

    $(window).on('resize', function(){
        slideSettings();
    });

}(jQuery));
