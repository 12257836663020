(function( $ ) {

    $('.solutions-list ul li .inner').matchHeight();
    $('.solution-details .solution-detail').matchHeight();
    $('.other-specs .other-spec .inner').matchHeight();

    var solutionsListBorderSet = function(){
        $('.solutions-list ul li').removeClass('border-bottom-none');
        var solLiCount = $('.solutions-list ul li').length;
        var windowWidth = $(window).width();
        if(windowWidth <= 480){
            $('.solutions-list ul li').removeClass('border-bottom-none');
        } else if(481 <= windowWidth && windowWidth < 768){
            if(solLiCount % 2 == 0){
                $('.solutions-list ul li:last-child').prev('li').andSelf().addClass("border-bottom-none");
            } else{
                $('.solutions-list ul li:last-child').addClass("border-bottom-none");
            }
        } else{

            if(solLiCount % 3 == 0){
                $('.solutions-list ul li:last-child').prev('li').andSelf().prev('li').andSelf().addClass("border-bottom-none");
            } else if(solLiCount % 3 == 2){
                console.log(solLiCount);
                $('.solutions-list ul li:last-child').prev('li').andSelf().addClass("border-bottom-none");
            } else{
                $('.solutions-list ul li:last-child').addClass("border-bottom-none");
            }
        }
    };



    $('.mobile-banking').owlCarousel({
        loop: false,
        nav: false,
        dots: true,
        lazyLoad: true,
        smartSpeed: 1000,
        margin:10,
        items:2,
        sideby:2,
        responsive:{
            641:{
                items:3,
                margin:20,
            },
            768:{
                items:4,
            },
            992:{
                items:5,
            }
        }
    });

    $('.retail-banking').owlCarousel({
        loop: false,
        nav: false,
        dots: true,
        lazyLoad: true,
        smartSpeed: 1000,
        margin:10,
        items:1,
        sideby:1,
        responsive:{
            768:{
                items:2,
            },
        }
    });

    $('.business-banking').owlCarousel({
        loop: false,
        nav: false,
        dots: true,
        lazyLoad: true,
        smartSpeed: 1000,
        margin:10,
        items:1,
        sideby:1,
        responsive:{
            768:{
                items:2,
            },
        }
    });

    $('.connect-banking').owlCarousel({
        loop: false,
        nav: false,
        dots: true,
        lazyLoad: true,
        smartSpeed: 1000,
        margin:10,
        items:1,
        sideby:1,
        responsive:{
            768:{
                items:2,
            },
        }
    });

    $('.virtual-assistance').owlCarousel({
        loop: false,
        nav: false,
        dots: true,
        lazyLoad: true,
        smartSpeed: 1000,
        margin:10,
        items:1,
        sideby:1,
        responsive:{
            768:{
                items:2,
            },
        }
    });

    $('.wr-solution-slider').owlCarousel({
        animateIn: 'fadeIn',
        animateOut: 'fadeOut',
        items:1,
        sideby:1,
        loop: true,
        nav: true,
        dots: false,
        lazyLoad: true,
        smartSpeed: 1000,
        //autoHeight:true
    });

    solutionsListBorderSet();

    $(window).on('load resize', function(){
        solutionsListBorderSet();
    });

}(jQuery));
