(function( $ ) {

    $('.news-item figure').matchHeight({byRow: false,});
    $('.news-item h3').matchHeight({byRow: false,});
    $('.home-products .product h2').matchHeight();
    $('.home-products .product p').matchHeight();
    $('.customer-story-sh').matchHeight();

    $(window).on('load resize', function(){

    });

    $('.home-products .product').each(function() {
        var homeProductImgHight = $(this).find('img').height();
        var $homeProductImg = $(this).find('img').attr('src');
        $(this).css({
            'background-image' : 'url(' + $homeProductImg + ')',
            //'height' : homeProductImgHight
        });
        $(this).find('img').remove();
    });


    $('.testimonials-slider').owlCarousel({
        items:1,
        autoplay:true,
        loop: true,
        nav: false,
        dots: true,
        lazyLoad: true,
        autoplayTimeout: 6000,
        smartSpeed: 1000,
    });

    var customerStorySlider = $('.customer-story-slider');
    customerStorySlider.owlCarousel({
        items:1,
        addClassActive : true,
        autoplay:true,
        loop: true,
        nav: false,
        dots: true,
        lazyLoad: true,
        autoplayTimeout: 6000,
        smartSpeed: 1000
    });

    $('.customer-stories').find('.arrow-link').click(function(e){
        e.preventDefault();
        customerStorySlider.trigger('next.owl.carousel');
    });

    $(window).scroll(function() {
        var windowPos = $(this).scrollTop();
        if(windowPos > 60){
            $('.scroll-down').fadeOut(300);
        }else{
            $('.scroll-down').fadeIn(300);
        }
    });

    $('.scroll-down').click(function() {
        var target = $('.scroll-content');
        if (target.length) {
            $('html, body').animate({
                scrollTop: target.offset().top - ($('#header').find('.navbar-default').height()),
            }, 1000);
            return false;
        }
    });

}(jQuery));
